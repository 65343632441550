<template>
  <div class="columns is-multiline p-0 m-0 full-quote-page-height">

    <!---------------------------------------------- parts -------------------------------------------->
    <div class="column is-one-third  br-gray-2">
      <div class="columns is-multiline mt-3">
        <div class="column p-0 m-0 is-full "
          style="height: 30px;">
          <div class="px-3 is-size-5 has-text-weight-bold">
            <span class="is-capitalized">Cancel Parts</span>

          </div>
        </div>

        <div class="column is-full ">
          <span class="w-100 bb-primary-2 has-text-primary mb-2">
            Parts
          </span>

          <div class="purchase-order-card-header has-text-weight-semibold my-4">

            <div>Description</div>
            <div style="direction: rtl;">
              Unit Cost
            </div>
            <div class="pl-5">Qty</div>
          </div>

          <div class="purchase-order-card-small mb-1 px-1"
            v-for="(part, index) in selectedItems"
            :key="index + 'part'">

            <div :content="part.description"
              v-tippy="{ enabled: true }"
              class="is-flex is-flex-direction-column">
              <span>
                {{ part.description }}
              </span>
            </div>
            <div style="direction: rtl;">{{ $filters.formatCurrency(part.unitPrice, $userInfo.locale) }}</div>

            <div style="align-items: center;"
              class="is-flex pl-5">
              <span>{{ part.quantity }}</span>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!---------------------------------------------- Invoice & Supplier -------------------------------------------->

    <div class="column is-one-third br-gray-2">
      <div class="columns is-multiline mt-3">
        <div class="column p-0 m-0 is-full "
          style="height: 30px;" />
        <div class="column is-full ">
          <span class="w-100 bb-primary-2 has-text-primary">
            Vehicle & Supplier
          </span>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Supplier
          </span>
          <div class="is-flex mt-2 ml-2">
            <span>{{ purchaseOrder.vendor.name }}</span>
          </div>
        </div>

        <div class="column is-full has-text-danger">
          <span class=" has-text-weight-semibold">
            ABN
          </span>
          <div class="is-flex mt-2 ml-2">
            <span>{{ purchaseOrder.vendor.abn || "No ABN Provided" }}</span>
          </div>
        </div>

        <div class="column is-full">
          <div class="column is-half m-0 p-0">
            <div class="field">
              <label class="label">Total Amt. (Incl GST)</label>

              <div class="is-flex mt-2 ml-2">
                {{ $filters.formatCurrency(totalAfterGst, $userInfo.locale ) }}
              </div>

            </div>
          </div>
        </div>

        <div class="column is-full">
          <div class="column is-half m-0 p-0">
            <div class="field">
              <label class="label">GST</label>

              <div class="is-flex mt-2 ml-2">
                {{ $filters.formatCurrency(totalGst, $userInfo.locale ) }}
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>

    <!---------------------------------------------- Details -------------------------------------------->
    <div class="column is-one-third">
      <div class="columns is-multiline mt-3">
        <div class="column p-0 m-0 is-full "
          style="height: 30px;">
          <div class="is-flex is-justify-content-end is">
            <div class="button mr-2 mt-3 is-danger is-outlined"
              @click="$emit('close')">Cancel</div>
            <div @click="savePo(false)"
              class="button mr-2 mt-3 is-success is-outlined">Cancel Parts</div>
          </div>
        </div>
        <div class="column is-full ">
          <span class="w-100 bb-primary-2 has-text-primary">
            Details
          </span>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Purchase Order Number
          </span>
          <div class="is-flex mt-2 ml-2">
            <span>PO. {{ purchaseOrder.purchaseOrderNo }}</span>
          </div>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Cancelled By
          </span>
          <div class="is-flex mt-2">
            <div class="field w-100">
              <div class="control is-expanded w-100">
                <bulma-autocomplete placeholder="R"
                  v-model="selectedReciever"
                  :data="recieverList"
                  :keep-first="true"
                  :loading="false"
                  @input="(v) => handleRecieverChange(v)" />
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="column is-full">
          <span class=" has-text-weight-semibold">
            Reason
          </span>
          <div class="is-flex mt-2">
            <div class="field w-100">
              <div class="control is-expanded w-100">
                <multiselect track-by="message"
                  label="message"
                  placeholder=""
                  :options="[
                    { message: 'Hello' },
                    { message: 'World' }
                  ]"
                  :loading="false"
                  :searchable="true"
                  :allow-empty="true"
                  @select="handleSelect" />
              </div>
            </div>
          </div>
        </div> -->

        <div class="column is-full">
          <span class=" has-text-weight-semibold">
            Comments
          </span>
          <div class="is-flex mt-2 w-100">
            <div class="field mb-0 w-100">
              <div class="control w-100">
                <textarea class="textarea"
                  rows="6"
                  maxlength="2000"
                  placeholder="Reason for cancelling, etc." />
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full ">
          <div class="field is-narrow">
            <label class="label">Date</label>
            <span>
              <input class="input is-static"
                :value="$filters.formatDateTimezone(selectedInvoiceDate, $userInfo.locale)"
                readonly>
            </span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// import Multiselect from 'vue-multiselect'
import _cloneDeep from 'lodash/cloneDeep'
import { roundAwayFromZero } from '../../../../utils/AccountingFunctions'
import PartsControlService from '../PartsControlService'
import DeepDiff from 'deep-diff'
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'

export default {
  name: 'PartsControlCancelParts',
  components: {
    // Multiselect,
    BulmaAutocomplete
  },
  props: {
    status: {
      type: String,
      default: ''
    },
    selectedItems: {
      type: Array,
      default: () => []
    },
    purchaseOrder: {
      type: Object,
      default: () => {}
    },
    readOnlyView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      creditorInvoiceId: '',
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      innerValue: _cloneDeep(this.purchaseOrder),
      invoiceError: null,
      selectedInvoiceDate: new Date(),
      validateError: false,
      recieverList: [],
      selectedReciever: null
    }
  },
  computed: {
    totalBeforeGst() {
      if (!this.selectedItems) return 0
      return roundAwayFromZero(
        this.selectedItems.reduce(function (total, item) {
          return total + item.dealerCost
        }, 0),
        2
      )
    },
    totalGst() {
      return this.totalBeforeGst * this.purchaseOrder.gstFactor
    },

    totalAfterGst() {
      return this.totalBeforeGst + this.totalGst
    }
  },
  created() {
    this.getRecieverList()
  },
  methods: {
    async savePo(isDelete) {
      const poItemIds = this.selectedItems.map((i) => i.purchaseOrderItemId)
      let newValue = _cloneDeep(this.innerValue)

      newValue.parts = newValue.parts.filter((i) => poItemIds.includes(i.purchaseOrderItemId))
      newValue.sublets = newValue.sublets.filter((i) => poItemIds.includes(i.purchaseOrderItemId))

      let originalValue = _cloneDeep(this.purchaseOrder)
      originalValue.parts = originalValue.parts.filter((i) => poItemIds.includes(i.purchaseOrderItemId))
      originalValue.sublets = originalValue.sublets.filter((i) => poItemIds.includes(i.purchaseOrderItemId))
      newValue.parts.forEach((i) => (i.isDeleted = true))
      newValue.sublets.forEach((i) => (i.isDeleted = true))

      const difference = DeepDiff.diff(originalValue, newValue)
      if (!difference && this.validateError) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        return false
      }

      try {
        this.$showSpinner('Saving...')
        let response
        response = await PartsControlService.putEntity(_cloneDeep(newValue), difference)
        this.$hideSpinner()
        this.$emit('close', 'You have successfully Cancelled the parts.', true)

        console.log(response)
      } catch (e) {
        console.log('error', e)
        this.$hideSpinner()
      }
    },
    async getRecieverList() {
      let recList = await PartsControlService.getOrderersDropdown()
      this.recieverList = recList
      const currentReciever = recList.find((i) => i.toLowerCase() === this.$userInfo?.displayName.toLowerCase())
      this.selectedReciever = currentReciever
    },
    handleRecieverChange(value) {
      this.selectedReciever = value
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/style/themes/default/_variables.scss';
.bb-primary-2 {
  border-bottom: 2px solid $primary;
}
.br-gray-2 {
  border-right: 1px solid #adb5bd;
}

.w-100 {
  display: flex;
  width: 100%;
}

.purchase-order-card-small {
  border: 1px solid #adb5bd;
  border-radius: 5px;
  height: 42px !important;
  align-items: center;
}

.purchase-order-card-header {
  border: 1px solid transparent;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.purchase-order-card-small,
.purchase-order-card-header {
  display: grid;
  ////  # Description  Price  Qty
  grid-template-columns: 60% 30% 10%;

  div {
    &.flex {
      display: flex;
      justify-content: center;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      align-items: center;
    }

    span {
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.date-field {
  border: 1px solid #adb5bd;
  border-radius: 5px;
}

.item-qty {
  min-width: 40px;
  max-width: 40px;
  input {
    width: 100%;
  }
}
</style>
