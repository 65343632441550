import FqApi from '@/services/fq-api'
// import ProtoBuf from 'protobufjs'

export default {
  async getEntity(id) {
    const url = `/returnparts/${id}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getEntitySummaries(filter) {
    const serialised = this.serialiseReturnPartsFilter(filter)
    const url = `/returnparts/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  // serialiseReturnPartsFilter(filter) {
  //   const entityProto = require('../../assets/proto/returnparts/ReturnPartsFilter.proto')
  //   const filterMessage = entityProto.ReturnPartsFilter.ReturnPartsFilterModel
  //   const buffer = filterMessage.encode(filter).finish()
  //   const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
  //   return b64encoded
  // },
  async getNewEntity() {
    var res = await FqApi.get('/returnparts/new')
    return res.data
  },
  async getReturnPartsItems(filter) {
    const serialised = this.serialiseReturnPartsItemFilter(filter)
    const url = `/returnparts/returnpartsitems/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  putReturnParts(entity, deepdiff) {
    if (deepdiff) {
      entity.deepDiff = JSON.stringify(deepdiff)
    }
    return FqApi.put(`/returnparts/${entity.creditReturnMainId}`, entity)
  },
  async postEntity(entity) {
    return FqApi.post('/returnparts', entity)
  }
  // serialiseReturnPartsItemFilter(filter) {
  //   const entityProto = require('../../assets/proto/returnparts/ReturnPartsItemFilter.proto')
  //   const filterMessage = entityProto.ReturnPartsItemFilter.ReturnPartsItemFilterModel
  //   const buffer = filterMessage.encode(filter).finish()
  //   const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
  //   return b64encoded
  // }
}
