<template>
  <div class="columns is-multiline p-0 m-0 full-quote-page-height">

    <!---------------------------------------------- parts -------------------------------------------->
    <div class="column is-one-third  br-gray-2">
      <div class="columns is-multiline mt-3">
        <div class="column p-0 m-0 is-full "
          style="height: 30px;">
          <div class="px-3 is-size-5 has-text-weight-bold is-flex is-flex-direction-column">
            <span class="is-capitalized">
              {{ orderReceipt ? 'Edit Order Receipt' : 'Recieve Parts' }}
            </span>
            <span v-if="isPartsCheckPurchaseOrder"
              class="is-size-7 has-text-danger">PartsCheck Orders can not be edited yet.</span>
          </div>
        </div>

        <div class="column is-full ">
          <span class="w-100 bb-primary-2 has-text-primary mb-2">
            Parts
          </span>

          <div class="purchase-order-card-header has-text-weight-semibold my-4">

            <div>Description</div>
            <div style="direction: rtl;">

              <span>Unit Cost</span>

            </div>
            <div style="direction: rtl;"
              class="pr-4">Qty</div>
          </div>

          <div class="purchase-order-card-small mb-1"
            :class="{'disabled' : !isEditableOrder}"
            v-for="(part, index) in filteredItems"
            :key="index + 'part'">

            <div :content="part.description"
              v-tippy="{ enabled: true }"
              class="is-flex is-flex-direction-column">
              <span class="ml-1">
                {{ part.description }}
              </span>
            </div>

            <div style="direction: rtl;">
              <vue-numeric class="input has-text-right"
                v-model.number="part.unitCost"
                :min="0"
                :precision="2"
                @input.native="updateBeforeGst(part)" />
            </div>

            <div style="align-items: center;"
              class="is-flex pl-5">
              <div class="item-qty mr-2">
                <vue-numeric class="input has-text-right"
                  :min="0"
                  :disabled="false"
                  :precision="0"
                  v-model.number="part.received"
                  @input="checkRecQty(part)" />
              </div>
              <span>/</span>
              <span>{{ part.orderQty }}</span>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!---------------------------------------------- Invoice & Supplier -------------------------------------------->

    <div class="column is-one-third br-gray-2">
      <div class="columns is-multiline mt-3">
        <div class="column p-0 m-0 is-full "
          style="height: 30px;" />
        <div class="column is-full ">
          <span class="w-100 bb-primary-2 has-text-primary">
            Invoice & Supplier
          </span>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Supplier
          </span>
          <div class="is-flex mt-2 ml-2">
            <span>{{ purchaseOrder.vendor.name }}</span>
          </div>
        </div>

        <div class="column is-full has-text-danger">
          <span class=" has-text-weight-semibold">
            ABN
          </span>
          <div class="is-flex mt-2 ml-2">
            <span>{{ purchaseOrder.vendor.abn }}</span>
          </div>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Invoice Number
          </span>
          <div class="field"
            :class="{'disabled' : !isEditableOrder}">
            <input class="input"
              :class="{ 'is-danger' : !receiptEntity.invoiceNo}"
              v-model="receiptEntity.invoiceNo"
              type="text"
              placeholder=""
              @input="checkInvoiceNoExists(receiptEntity)">
          </div>
        </div>

        <div class="column is-full">
          <div class="column is-half m-0 p-0">
            <div class="field">
              <label class="label">Total Amt. (Incl GST)</label>
              <div class="control">
                <vue-numeric class="input has-text-right"
                  :class="{'disabled' : !isEditableOrder}"
                  v-model="receiptEntity.overrideIncGst"
                  type="text"
                  placeholder=""
                  :precision="2"
                  @input.native="updateOverrideTotal(receiptEntity)" />
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full">
          <div class="column is-half m-0 p-0">
            <div class="field is-narrow">
              <label class="label">GST</label>
              <div class="control">
                <vue-numeric v-if="!readOnlyView && receiptEntity.receiptItems && receiptEntity.receiptItems.length > 0"
                  class="input has-text-right"
                  v-model="receiptEntity.overrideGst"
                  type="text"
                  :class="{'disabled' : !isEditableOrder}"
                  placeholder=""
                  :precision="2"
                  @blur="checkOverrideGst(receiptEntity)" />
                <span v-else>
                  {{ $filters.formatCurrency(receiptEntity.overrideGst, $userInfo.locale ) }}
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!---------------------------------------------- Details -------------------------------------------->
    <div class="column is-one-third">
      <div class="columns is-multiline mt-3">
        <div class="column p-0 m-0 is-full "
          style="height: 30px;">
          <div class="is-flex is-justify-content-end">

            <div class="button mt-3 mr-2 is-danger is-outlined"
              v-if="!!orderReceipt"
              :class="{'disabled' : !isEditableOrder}"
              @click="deleteReceipt">
              <span class="mdi mdi-delete mdi-24px" />
            </div>

            <div class="button mr-2 mt-3 is-danger is-outlined"
              @click="$emit('close')">Cancel</div>
            <div @click="savePo(false)"
              class="button mr-2 mt-3 is-success is-outlined"
              :class="{ 'disabled' : !isEditableOrder }">
              {{ !!orderReceipt ? 'Update Invoice' : 'Receive Parts' }}
            </div>
          </div>
        </div>
        <div class="column is-full ">
          <span class="w-100 bb-primary-2 has-text-primary">
            Details
          </span>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Purchase Order Number
          </span>
          <div class="is-flex mt-2 ml-2">
            <span>PO. {{ purchaseOrder.purchaseOrderNo }}</span>
          </div>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Received By
          </span>
          <div class="is-flex mt-2">
            <div class="field w-100">
              <div class="control is-expanded w-100">
                <bulma-autocomplete placeholder="Receiver"
                  :class="{'disabled' : !isEditableOrder}"
                  v-model="selectedReciever"
                  :data="filteredReceiverList"
                  :keep-first="true"
                  :loading="false"
                  @input="(v) => handleRecieverChange(v)" />
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full ">
          <div class="field is-narrow">
            <label class="label">Date</label>
            <v-date-picker v-if="receiptEntity.receiptItems && receiptEntity.receiptItems.length > 0"
              v-model="innerValue.purchaseOrderDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      :class="{'disabled' : !isEditableOrder}"
                      placeholder="Invoice Date"
                      :value="inputValue"
                      v-on="inputEvents"
                      readonly>
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
            <span v-else>
              <input class="input is-static"
                :value="$filters.formatDateTimezone(selectedInvoiceDate, $userInfo.locale)"
                readonly>
            </span>
          </div>
        </div>

        <div class="column is-full">
          <div class="field">
            <div class="file is-primary"
              :class="{'disabled' : !isEditableOrder}">
              <label class="file-label">
                <!-- <input class="file-input" type="file" name="resume"> -->
                <input type="file"
                  class="file-input"
                  :name="uploadFieldName"
                  @change="filesChange($event.target.name, $event.target.files);fileCount = $event.target.files.length"
                  accept="image/png, image/jpeg, .pdf">
                <span class="file-cta">
                  <span class="file-icon">
                    <i class="mdi mdi-upload mdi-20px" />
                  </span>
                  <span class="file-label">
                    Select files...
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>

        <div class="column is-full">
          <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
            :columns="attachmentsColumns"
            :page-index="1"
            :page-size="100"
            :is-loading="isLoadingAttachmentData"
            :total-rows="attachments.length"
            :show-pagination="false"
            :sort-column="sortColumn"
            :sort-order="sortOrder"
            @sort="sort">
            <tr v-for="(item, index) in attachments"
              :key="item.id"
              :class="{ 'is-selected' : selectedRow === index }">
              <td>{{ $filters.formatDateTimeLocale(item.createdDate, $userInfo.locale) }}</td>
              <td class="has-vertical-middle">
                <a href="#"
                  @click="downloadAttachment(item)">{{ item.fileName }}</a>
              </td>
              <td>{{ item.fileSize }}</td>
              <td class="has-vertical-middle has-text-centered is-content-width">
                <button class="button is-danger is-small is-inverted"
                  @click="deleteAttachment(item)"
                  :disabled="readOnlyView || receiptEntity.receiptItems.length === 0 || !isEditableOrder">
                  <span class="icon is-medium">
                    <i class="mdi mdi-delete mdi-24px" />
                  </span>
                </button>
              </td>
            </tr>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <span icon="icon is-medium">
                    <i class="mdi mdi-40px mdi-emoticon-sad" />
                  </span>
                  <p>No Atachments Selected</p>
                </div>
              </section>
            </template>
          </bulma-table>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// import Multiselect from 'vue-multiselect'
import Guid from '@/components/Guid.js'
import { PurchaseOrderReceiptModel, PurchaseOrderReceiptItemModel } from '@/classes/viewmodels'
import _cloneDeep from 'lodash/cloneDeep'
import VueNumeric from '@/components/VueNumeric'
import { roundAwayFromZero } from '@/utils/AccountingFunctions'
import { InvoicePrefixShortFormTypes } from '@/enums'
import PartsControlService from '../../PartsControlService'
import DeepDiff from 'deep-diff'
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'
import UploadService from '@/components/FileUploadService'
import BulmaTable from '@/components/BulmaTable/BulmaTable.vue'
import { AttachmentsColumns } from './column'

const title = 'Order Receipt'

export default {
  name: 'PartsControlReceiveParts',
  components: {
    // Multiselect,
    VueNumeric,
    BulmaAutocomplete,
    BulmaTable
  },
  props: {
    selectedItems: {
      type: Array,
      default: () => []
    },
    purchaseOrder: {
      type: Object,
      default: () => {}
    },
    orderReceipt: {
      type: Object,
      default: null
    },
    readOnlyView: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      creditorInvoiceId: '',
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      innerValue: _cloneDeep(this.purchaseOrder),
      invoiceError: null,
      isValidInvoice: false,
      selectedInvoiceDate: new Date(),
      validateError: false,
      recieverList: [],
      selectedReciever: null,
      quoteAttchmentCategories: [],
      uploadFieldName: '',
      fileCount: 0,
      uploadedFiles: [],
      isLoadingAttachmentData: false,
      attachments: [],
      sortColumn: '',
      sortOrder: '',
      selectedRow: false
    }
  },
  computed: {
    isPartsCheckPurchaseOrder() {
      return this.purchaseOrder.partsCheckPurchaseOrderNo !== ''
    },
    isEditableOrder() {
      return !this.isPartsCheckPurchaseOrder && this.isEditable
    },
    filteredReceiverList() {
      return this.recieverList.filter((i) => i.toLowerCase().includes(this.selectedReciever?.toLowerCase()))
    },
    receiptEntity() {
      return this.innerValue.orderReceipts.find((receipt) => receipt.creditorInvoiceId === this.creditorInvoiceId) || {}
    },
    activeItems() {
      return this.receiptEntity.receiptItems
    },
    filteredItems() {
      return this.innerValue.orderReceipts.find((receipt) => receipt.creditorInvoiceId === this.creditorInvoiceId)?.receiptItems || []
    },
    poItems() {
      return this.innerValue.parts.concat(this.innerValue.miscs, this.innerValue.sublets, this.innerValue.otherPurchases)
    },
    validItemQtyForReceipt() {
      return this.filteredItems.some((item) => item.received && item.received <= item.owning)
    },
    attachmentsColumns() {
      return AttachmentsColumns
    },
    totalBeforeGst() {
      if (!this.activeItems) return 0
      return roundAwayFromZero(
        this.activeItems
          .filter((i) => i.received !== 0)
          .reduce(function (total, item) {
            return total + item.dealerCost
          }, 0),
        2
      )
    },
    totalGst() {
      if (!this.activeItems) return 0
      else return roundAwayFromZero(this.totalBeforeGst * (this.innerValue.gstRate / 100), 2)
    },
    totalAfterGst() {
      if (!this.activeItems) return 0
      else return roundAwayFromZero(this.totalBeforeGst + this.totalGst, 2)
    }
  },
  watch: {
    purchaseOrder: {
      handler: function (val) {
        this.saveSnapshot = _cloneDeep(val)
      },
      deep: true
    },
    totalBeforeGst(newVal, oldVal) {
      if (newVal) {
        this.receiptEntity.amount = newVal
        this.receiptEntity.overrideExGst = newVal
      }
    },
    totalAfterGst(newVal, oldVal) {
      if (newVal) {
        this.receiptEntity.totalAmount = newVal
        this.receiptEntity.overrideIncGst = newVal
      }
    },
    totalGst(newVal, oldVal) {
      if (newVal) {
        this.receiptEntity.gstAmount = newVal
        this.receiptEntity.overrideGst = newVal
      }
    },
    receiptEntity(newVal, oldVal) {
      this.checkInvoiceNoExists(newVal)
    }
  },

  async mounted() {
    this.quoteAttchmentCategories = await PartsControlService.getQuoteAttachmentCategories()
    this.getRecieverList()
    // new order receipt
    if (!this.orderReceipt) {
      this.createNewOrderReceipt()
    } else {
      // edit order receipt
      this.creditorInvoiceId = this.orderReceipt.creditorInvoiceId
    }
    this.getAttachments()
  },
  methods: {
    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData()

      if (!fileList.length) return
      const vm = this
      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        const regex = fileList[x].name.match(/\.([0-9a-z]+)(?:[?#]|$)/)
        const ext = regex[1]
        switch (ext) {
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'pdf':
            formData.append(fieldName, fileList[x], fileList[x].name)
            // save it
            this.save(formData)
            break
          default:
            vm.$notification.openNotificationWithType('danger', 'Attachments', 'File type not allowed. Only JPG, JPEG, PNG & PDF are allowed')
        }
      })
    },

    async deleteAttachment(attachment) {
      this.isLoadingAttachmentData = true
      await UploadService.delete(attachment.id)
      this.getAttachments()
      // this.$refs.uploader.reset()
      this.isLoadingAttachmentData = false
    },
    async downloadAttachment(item) {
      const response = await UploadService.get(item.id)
      var blob = new Blob([response.data], {
        type: response.headers['content-type']
      })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = item.fileName
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      delete link.href
      return false
    },
    getQuoteAttachmentCategoryId(categoryName) {
      return this.quoteAttchmentCategories.find((i) => i.name === categoryName)?.quoteAttachmentCategoryId || 'xxx'
    },
    async save(formData) {
      this.isLoadingAttachmentData = true

      const quoteAttachmentCategoryId = this.getQuoteAttachmentCategoryId('Supplier Invoices')
      UploadService.upload(formData, this.receiptEntity.creditorInvoiceId, this.receiptEntity.quoteId, quoteAttachmentCategoryId)
        .then((x) => {
          this.uploadedFiles = [].concat(x)

          this.getAttachments()
          this.$refs.uploader.reset()
          this.isLoadingAttachmentData = false
        })
        .catch((err) => {
          this.uploadError = err.response
          this.isLoadingAttachmentData = false
        })
    },

    async getAttachments() {
      this.isLoadingAttachmentData = true
      let creditorInvoiceId = this.creditorInvoiceId
      const req = await PartsControlService.getAttachments(creditorInvoiceId)
      this.attachments = req.data.list
      this.isLoadingAttachmentData = false
    },

    createNewOrderReceipt() {
      let creditorInvoiceId = Guid.newGuid()
      this.creditorInvoiceId = creditorInvoiceId

      const newReceipt = new PurchaseOrderReceiptModel(
        this.innerValue.companyId,
        this.innerValue.purchaseOrderId,
        creditorInvoiceId,
        this.innerValue.vendorId,
        this.innerValue.vendor.abn,
        this.innerValue.vendor.name,
        this.innerValue.quoteId,
        this.innerValue.vendorGlCode,
        this.innerValue.gstRate
      )
      newReceipt.invoiceDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()) //new Date().toISOString()
      this.innerValue.orderReceipts.push(newReceipt)

      let lastIndex = this.innerValue.orderReceipts.length - 1
      let receiptItems = this.selectedItems

      if (receiptItems.length > 0) {
        for (let i = 0; i < receiptItems.length; i++) {
          if (receiptItems[i].quantity > receiptItems[i].receivedQty - receiptItems[i].returnQty) {
            const newItem = new PurchaseOrderReceiptItemModel(
              this.innerValue.companyId,
              creditorInvoiceId,
              this.innerValue.vendorId,
              receiptItems[i].purchaseOrderItemId
            )
            const date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
            newItem.quote2ItemId = receiptItems[i].quoteItemId !== null ? receiptItems[i].quoteItemId : Guid.empty()
            newItem.receivedDate = date.toISOString().split('.')[0] + 'Z'
            newItem.account = this.innerValue.vendorGlCode
            newItem.description = receiptItems[i].description
            newItem.itemNo = receiptItems[i].itemNo
            newItem.side = receiptItems[i].side
            newItem.orderQty = receiptItems[i].quantity
            newItem.owning = receiptItems[i].quantity - receiptItems[i].receivedQty + receiptItems[i].returnQty
            newItem.totalReceivedQty = receiptItems[i].receivedQty
            newItem.partNo = receiptItems[i].partNo
            newItem.unitCost = receiptItems[i].unitPrice
            newItem.dealerCost =
              (receiptItems[i].quantity - (receiptItems[i].receivedQty - receiptItems[i].returnQty)) * (receiptItems[i].dealerCost / receiptItems[i].quantity)
            newItem.gstAmount = newItem.dealerCost * (this.innerValue.gstRate / 100)
            newItem.totalAmount = newItem.dealerCost + newItem.gstAmount
            newItem.received = receiptItems[i].quantity - (receiptItems[i].receivedQty - receiptItems[i].returnQty)
            this.innerValue.orderReceipts[lastIndex].receiptItems.push(newItem)
          }
        }
      }
    },
    updateBeforeGst(item) {
      const dealerCost = item.unitCost * item.received
      item.dealerCost = roundAwayFromZero(dealerCost, 2)
      item.gstAmount = this.innerValue.gstDivisor == 0 ? 0 : roundAwayFromZero(item.dealerCost * (this.innerValue.gstRate / 100), 2)
      item.totalAmount = roundAwayFromZero(item.dealerCost + item.gstAmount, 2)
      this.receiptEntity.totalAmount = this.totalAfterGst
    },
    checkRecQty(item) {
      if (item.received > item.owning - item.returnedQty || 0) {
        item.received = item.owning - item.returnedQty
        const alreadyReceived = item.orderQty - item.owning
        if (alreadyReceived > 0) {
          this.$toast.open({
            message: 'You have already received ' + alreadyReceived + ' items',
            type: 'is-danger ',
            position: 'is-bottom',
            queue: false
          })
        }
        this.$toast.open({
          message: 'Received Qty cannot be more than Ordered Qty & returned Qty.',
          type: 'is-danger ',
          position: 'is-bottom',
          queue: false
        })
      }

      //calculate totalReceivedQty for all receipt order for the same PO item
      let totalRec = 0
      this.innerValue.orderReceipts.forEach(function (receipt) {
        let receiptItem = receipt.receiptItems.find((i) => i.purchaseOrderItemId === item.purchaseOrderItemId)
        if (!!receiptItem.received) {
          totalRec = totalRec + receiptItem.received
        }
      })
      // update totalReceivedQty in all order receipt for the same PO item
      this.innerValue.orderReceipts.forEach(function (receipt) {
        let found = receipt.receiptItems.some((i) => i.purchaseOrderItemId === item.purchaseOrderItemId)
        if (found) {
          let receiptItem = receipt.receiptItems.filter((i) => i.purchaseOrderItemId === item.purchaseOrderItemId)[0]
          receiptItem.totalReceivedQty = totalRec
        }
      })
      // update totalReceivedQty in AT_PurchaseOrderItem table
      var poItem = this.poItems.filter((i) => i.purchaseOrderItemId === item.purchaseOrderItemId && !i.deleted)[0]
      poItem.receivedQty = totalRec
      this.$nextTick(() => {
        this.checkQtyAmount(this.receiptEntity)
      })

      this.$nextTick(() => {
        this.updateBeforeGst(item)
      })
    },
    updateOverrideTotal(receipt) {
      // receipt.overrideGst = this.innerValue.gstDivisor == 0 ? 0 : roundAwayFromZero(receipt.overrideIncGst / this.innerValue.gstDivisor, 2)
      receipt.overrideExGst = receipt.overrideIncGst - receipt.overrideGst
      var dUpp = roundAwayFromZero(this.totalAfterGst + 0.05, 2)
      var dLow = roundAwayFromZero(this.totalAfterGst - 0.05, 2)
      if (receipt.overrideIncGst < dLow || (receipt.overrideIncGst > dUpp && receipt.overrideIncGst != 0)) {
        receipt.overrideIncGst = roundAwayFromZero(this.totalAfterGst, 2)
        this.$notification.openNotificationWithType('danger', title, 'Total Invoice amount should be between ' + dLow + ' and ' + dUpp)
        return false
      }
      this.checkQtyAmount(receipt)
    },
    checkQtyAmount(receipt) {
      if (this.totalReceiptQty === 0 || (receipt.totalAmount === 0 && receipt.overrideIncGst === 0)) {
        this.isValidQtyAmt = false
      } else {
        this.isValidQtyAmt = true
      }
    },
    checkOverrideGst(receipt) {
      if (receipt.overrideGst > 0) {
        var dUpp = this.innerValue.gstDivisor == 0 ? 0 : roundAwayFromZero(receipt.overrideIncGst / this.innerValue.gstDivisor + 0.1, 2)

        var dLow = this.innerValue.gstDivisor == 0 ? 0 : roundAwayFromZero(receipt.overrideIncGst / this.innerValue.gstDivisor - 0.1, 2)

        if (receipt.overrideGst < dLow || (receipt.overrideGst > dUpp && receipt.overrideGst != 0)) {
          receipt.overrideGst = this.innerValue.gstDivisor == 0 ? 0 : roundAwayFromZero(receipt.overrideIncGst / this.innerValue.gstDivisor, 2)
          this.$toast.open({
            message: 'GST amount should be zero or between ' + dLow + ' and ' + dUpp,
            type: 'is-danger ',
            position: 'is-bottom',
            queue: false
          })

          return false
        }
      }
    },
    async checkInvoiceNoExists(receipt) {
      if (receipt.invoiceNo) {
        let found = receipt.invoiceNo.toUpperCase().includes(InvoicePrefixShortFormTypes.RFC)
        if (found) {
          this.invoiceError = 'Cannot use ' + InvoicePrefixShortFormTypes.RFC + ' as part of invoice number.'
          this.isValidInvoice = false
        } else {
          let invoiceId = await PartsControlService.checkInvoiceNoExists(receipt.invoiceNo, this.innerValue.vendorId, receipt.creditorInvoiceId)

          if (invoiceId === Guid.empty()) {
            this.isValidInvoice = true
            this.invoiceError = null
          } else {
            this.isValidInvoice = false
            this.invoiceError = 'Invoice Number already in use.'
          }
        }
      } else {
        this.isValidInvoice = false
        this.invoiceError = 'Invoice Number is required.'
      }
    },

    sort(name, order) {
      this.receiptEntity.receiptItems = _orderBy(this.activeItems, name, order)
    },
    async savePo(isDelete = false) {
      const difference = DeepDiff.diff(this.purchaseOrder, this.innerValue)

      if (!difference && this.validateError && !isDelete) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        return false
      }

      if (!this.validItemQtyForReceipt && !isDelete) {
        this.$notification.openNotificationWithType('danger', title, 'Please select at least one part to receive.')
        return false
      }

      if (!this.isValidInvoice) {
        this.$notification.openNotificationWithType('danger', title, this.invoiceError)
        return false
      }

      try {
        this.$showSpinner('Saving...')
        let response
        response = await PartsControlService.putEntity(_cloneDeep(this.innerValue), difference)
        this.$hideSpinner()
        console.log(response)
        this.$emit('close', 'You have successfully received the parts.', true)
      } catch (e) {
        if (e.response.request && e.response.request.status === HttpStatus.CONFLICT) {
          this.isSaveConflictModalActive = true
        } else {
          this.$notification.openMessageXhrError(title, e)
        }
      }
    },
    async getRecieverList() {
      let recList = await PartsControlService.getOrderersDropdown()
      this.recieverList = recList
      const currentReciever = recList.find((i) => i.toLowerCase() === this.$userInfo?.displayName.toLowerCase())
      this.selectedReciever = currentReciever
    },
    handleRecieverChange(value) {
      this.selectedReciever = value
    },
    ///////////////////////////////////////////////////////////////////////Delete Order Receipts ///////////////////////////////////////////////////
    async deleteReceipt() {
      if (!this.orderReceipt) {
        this.$notification.openNotificationWithType('danger', title, 'No order receipt to delete.')
        return false
      }

      const purchaseOrderCopy = _cloneDeep(this.purchaseOrder)

      // save a copy of the original receipt
      const currentReceipt = _cloneDeep(purchaseOrderCopy.orderReceipts.find((receipt) => receipt.creditorInvoiceId === this.orderReceipt.creditorInvoiceId))

      // receipt to modify
      let receipt = purchaseOrderCopy.orderReceipts.find((receipt) => receipt.creditorInvoiceId === this.orderReceipt.creditorInvoiceId)

      // if some parts have been returned, do not allow deletion
      if (receipt.isReturn) {
        this.$notification.openNotificationWithType('danger', title, 'Parts have been returned. You cannot delete this receipt.')
        return false
      }

      if (receipt.gstPaid !== null) {
        this.$notification.openNotificationWithType('danger', title, 'This order receipt cannot be deleted. GST amount has been paid.')
        return false
      }

      const poItems = purchaseOrderCopy.parts.concat(purchaseOrderCopy.miscs, purchaseOrderCopy.sublets, purchaseOrderCopy.otherPurchases)

      // update receivedQty and totalInStock for all PO items
      for (let i = 0; i < receipt.receiptItems.length; i++) {
        var poItemId = receipt.receiptItems[i].purchaseOrderItemId
        // var poItem = this.poItems.filter(i => i.purchaseOrderItemId === poItemId && !i.deleted)[0]
        var poItem = poItems.find((i) => i.purchaseOrderItemId === poItemId)
        poItem.receivedQty = poItem.receivedQty - currentReceipt.receiptItems[i].received
        poItem.totalInStock = poItem.totalInStock - currentReceipt.receiptItems[i].received

        //calculate totalReceivedQty for all receipt order for the same PO item
        purchaseOrderCopy.orderReceipts.forEach(function (receipt) {
          let found = receipt.receiptItems.some((i) => i.purchaseOrderItemId === poItemId)
          if (found) {
            receipt.receiptItems.filter((i) => i.purchaseOrderItemId === poItemId)[0].totalReceivedQty = poItem.receivedQty
          }
        })
      }

      if (receipt.isNew) {
        const itemIndex = purchaseOrderCopy.orderReceipts
          .map(function (obj) {
            return obj.creditorInvoiceId
          })
          .indexOf(receipt.creditorInvoiceId)
        if (itemIndex >= 0) {
          purchaseOrderCopy.orderReceipts.splice(itemIndex, 1)
        }
      } else {
        receipt.deleted = true
        receipt.isDeleted = true
      }

      const difference = DeepDiff.diff(this.purchaseOrder, purchaseOrderCopy)
      let response = await PartsControlService.putEntity(_cloneDeep(purchaseOrderCopy), difference)
      if (!!response) this.$emit('close', 'Order Receipt deleted', true)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/themes/default/_variables.scss';
.bb-primary-2 {
  border-bottom: 2px solid $primary;
}
.br-gray-2 {
  border-right: 1px solid #adb5bd;
}

.w-100 {
  display: flex;
  width: 100%;
}

.purchase-order-card-small {
  border: 1px solid #adb5bd;
  border-radius: 5px;
  align-items: center;
  height: 42px !important;
}

.purchase-order-card-header {
  border: 1px solid transparent;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.purchase-order-card-small,
.purchase-order-card-header {
  display: grid;
  ////  # Description  Price  Qty
  grid-template-columns: 50% 30% 20%;

  div {
    &.flex {
      display: flex;
      justify-content: center;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      align-items: center;
    }

    span {
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.date-field {
  border: 1px solid #adb5bd;
  border-radius: 5px;
}

.item-qty {
  min-width: 40px;
  max-width: 40px;
  input {
    width: 100%;
  }
}
</style>
