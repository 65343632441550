import { TableColumn } from '@/classes'

const AttachmentsColumns = [
  new TableColumn('Date', 'date', false, 'desc', 'left'),
  new TableColumn('File name', 'fileName', false, 'desc', 'left'),
  new TableColumn('File size', 'fileSize', false, 'desc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'left')
]

export {
  AttachmentsColumns
}