import {  mapActions } from 'vuex'

const moduleName = 'PartsControl'

export default {
  methods: {
    ...mapActions(moduleName, ['getStoreItem', 'addStoreItem', 'saveSnapshot', 'clearSnapshots', 'setReturnRoute', 'setReadOnlyView', 'setLockPurchaseOrder']),
    ...mapActions('quotes', { getQuoteVehicle: 'getQuoteVehicle',getQuoteCustomer: 'getQuoteCustomer', getQuoteInsurer: 'getQuoteInsurer', removeQuoteVehicle: 'removeQuoteVehicle', removeQuoteCustomer: 'removeQuoteCustomer', removeQuoteInsurer: 'removeQuoteInsurer' }),
    ...mapActions('vendors', { setVendorReturnRoute: 'setReturnRoute', addStoreVendor: 'addStoreItem', editStoreVendor: 'editStoreItem' }),
    ...mapActions('contacts', { setContactReturnRoute: 'setReturnRoute', addStoreContact: 'addStoreItem', editStoreContact: 'editStoreItem' }),
    ...mapActions('printpreview', { setPrintPreviewReturnRoute: 'setReturnRoute', addEmailer: 'addEmailer', removeEmailer: 'removeEmailer' }),
    ...mapActions('printpreview', { addReportParameters: 'addParameters', removeReportParameters: 'removeParameters' })
  }
}
