<!--
Draggable Usage:
  <draggable v-model="items"
      :options="{'disabled': !isRowDraggable, chosen: 'chosen', ghostClass: 'draggable-ghost', dragClass: 'draggable-drag'}"
      :element="'tbody'"
      @change="itemMoved"
      @end="endMove">
      <tr v-for="(item, index) in items"
        v-bind:key="item.itemId">
      </tr>
    </draggable>
-->
<template>
  <table class="table"
    :class="[ isLoading ? 'is-loading' : '', hasStickyHeader ? 'has-sticky-header' : '' ]"
    :style="`--sticky-header-top: ${stickyHeaderTop}`">
    <slot name="colgroup" />
    <thead>
      <slot name="header" />
      <tr v-if="showHeader">
        <th v-if="isTitleCheckable && titleCheckboxLocation === 'front'"
          class="has-vertical-middle has-text-centered">
          <div class="pretty p-icon p-curve p-smooth m-0 p-bigger">
            <input v-model="isChecked"
              type="checkbox">
            <div class="state p-info">
              <i class="icon mdi mdi-check" />
              <label />
            </div>
          </div>
        </th>
        <th v-for="(column, index) in columns"
          v-show="column.isVisible"
          :class="[`has-text-${column.align}`]"
          :key="index">
          <a v-if="column.sortable"
            @click="sort(column)">
            {{ column.title }}
            <span v-show="currentSortColumn === column.name"
              class="icon is-small"
              :class="{ 'is-desc' : !isAsc }">
              <i class="sort-icon" />
            </span>
          </a>
          <span v-else>{{ column.title }}</span>
        </th>
        <th v-if="isTitleCheckable && titleCheckboxLocation === 'end'"
          class="has-vertical-middle">
          <div class="pretty p-icon p-curve p-smooth m-0 p-bigger">
            <input v-model="isChecked"
              type="checkbox">
            <div class="state p-info">
              <i class="icon mdi mdi-check" />
              <label />
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tfoot v-if="totalRows > 0 || showFooter">
      <slot name="footer" />
      <tr v-if="showPagination">
        <th :colspan="isTitleCheckable ? columns.length + 1 : columns.length">
          <pagination class=""
            :current-page="pageIndex"
            :total-rows="totalRows"
            :page-size="pageSize"
            @pageChanged="onPageChange" />
        </th>
      </tr>
    </tfoot>
    <slot v-if="totalRows > 0 && draggable" />
    <tbody v-else-if="totalRows > 0 && !draggable">
      <slot />
    </tbody>
    <tbody v-else-if="!isLoading && !draggableEmpty">
      <tr>
        <td :colspan="isTitleCheckable ? columns.length + 1 : columns.length">
          <slot name="empty" />
        </td>
      </tr>
    </tbody>
    <slot v-else-if="!isLoading && draggableEmpty"
      name="empty" />
    <tbody v-else-if="isLoading">
      <tr>
        <td :colspan="isTitleCheckable ? columns.length + 1 : columns.length">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <span icon="icon is-large"
                style="opacity: 0;">
                <i class="mdi mdi-spin mdi-loading mdi-36px" />
              </span>
            </div>
          </section>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Pagination from '@/components/BulmaPagination'

export default {
  name: 'BulmaTable',
  components: {
    Pagination
  },
  props: {
    columns: Array,
    pageIndex: Number,
    pageSize: Number,
    totalRows: Number,
    isLoading: {
      type: Boolean,
      default: false
    },
    sortColumn: String,
    sortOrder: String,
    showPagination: {
      type: Boolean,
      default: true
    },
    showFooter: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    draggable: {
      type: Boolean,
      default: false
    },
    isTitleCheckable: {
      type: Boolean,
      default: false
    },
    titleCheckboxLocation: {
      type: String,
      default: 'end'
    },
    isTitleChecked: {
      type: Boolean,
      default: false
    },
    draggableEmpty: {
      type: Boolean,
      default: false
    },
    hasStickyHeader: {
      type: Boolean,
      default: false
    },
    stickyHeaderTop: {
      type: String,
      default: '0'
    }
  },
  data() {
    return {
      currentSortColumn: this.columns[0].name,
      currentSortOrder: this.columns[0].defaultOrder,
      isChecked: this.isTitleChecked
    }
  },
  computed: {
    isAsc() {
      return this.currentSortOrder === 'asc'
    },
    sortProperties() {
      return {
        name: this.sortColumn,
        order: this.sortOrder
      }
    }
  },
  watch: {
    sortProperties(value) {
      if (value.name !== this.currentSortColumn || value.order !== this.currentSortOrder) {
        this.currentSortColumn = value.name
        this.currentSortOrder = value.order
        // this.$emit('sort', this.currentSortColumn, this.currentSortOrder)
      }
    },
    sortColumn(value) {
      this.currentSortColumn = value
    },
    sortOrder(value) {
      this.currentSortOrder = value
    },
    isChecked(newVal) {
      this.$emit('update:isTitleChecked', newVal)
      this.$emit('title-checkbox-changed', newVal)
    },
    isTitleChecked(newVal) {
      this.isChecked = this.isTitleChecked
    }
  },
  created() {
    this.currentSortColumn = this.sortColumn
    this.currentSortOrder = this.sortOrder
  },
  mounted() {},
  methods: {
    switchOrder() {
      this.currentSortOrder = ['asc', 'desc'].find((s) => s !== this.currentSortOrder)
    },
    onPageChange(pageIndex) {
      this.$emit('pageChanged', pageIndex)
    },
    sort(column, isChangeOrder = true) {
      if (this.currentSortColumn === column.name) {
        this.switchOrder()
      } else {
        this.currentSortOrder = column.defaultOrder
      }
      this.currentSortColumn = column.name
      this.$emit('sort', column.name, this.currentSortOrder)
    }
  }
}
</script>

<style lang="scss" scoped>
.hide-column {
  display: none;
}
.table.has-sticky-header {
  thead {
    > tr th {
      top: var(--sticky-header-top);
    }
  }
}
</style>
