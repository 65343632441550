<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="60"
    has-modal-card>
    <header class="modal-card-head">
      <div class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <!-- <slot name="text-title"></slot> -->
        <span>{{ title }}</span>
      </div>
    </header>
    <section class="modal-card-body">
      <image-select v-if="innerValue"
        v-model="selectedImages"
        :quote-id="quoteId"
        :is-loading.sync="isLoadingImages" />
    </section>

    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-success tooltip"
        @click="submit()"
        data-tooltip="Submit parts"
        :disabled="isLoadingImages">
        <span class="icon is-small">
          <i class="mdi mdi-18px mdi-upload" />
        </span>
        <span>Attach</span>
      </button>
      <button class="button tooltip"
        @click="cancel()"
        data-tooltip="Cancel"
        :disabled="cancelDisabled">
        <span>Cancel</span>
      </button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import ImageSelect from '@/components/ImageSelect'
//import { PartsCheckService } from '../services'

export default {
  name: 'PricePartsImageSelectionModal',
  components: {
    BaseModalEx,
    ImageSelect
  },
  props: {
    value: null,
    active: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: 'PartsCheck Submit Images'
    },

    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-upload'
    },
    copyDisabled: {
      type: Boolean,
      default: false
    },
    cancelDisabled: {
      type: Boolean,
      default: false
    },
    quoteId: ''
  },
  data() {
    return {
      isActive: false,
      innerValue: null,
      selectedImages: [],
      isLoadingImages: false
      // quoteId:null
    }
  },
  validations: {},
  computed: {},
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  created() {
    this.innerValue = this.value
    this.selectedImages = this.value
    //this.quoteId = this.innerValue
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    async submit() {
      this.isActive = false
      const imageIds = this.selectedImages.map((i) => i.quoteImageId)
      this.$emit('selected-image-ids', imageIds)
      this.$emit('update:active', false)
    },
    cancel() {
      this.isActive = false
      this.$emit('update:active', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
