import FqApi from '@/services/fq-api'

const baseUrl = '/purchaseorders'

export default {
  async getPurchaseOrdersForQuote(quoteId) {
    const url = `${baseUrl}/quote/${quoteId}`
    const result = await FqApi.get(url)
    return result.data
  }
}