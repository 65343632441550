<template>
  <div class="columns is-multiline p-0 m-0 full-quote-page-height">

    <!---------------------------------------------- parts -------------------------------------------->
    <div class="column is-one-third  br-gray-2">
      <div class="columns is-multiline mt-3">
        <div class="column p-0 m-0 is-full "
          style="height: 30px;">
          <div class="px-3 is-size-5 has-text-weight-bold">
            <span class="is-capitalized">
              Create a new Purchase Order
            </span>
          </div>
        </div>

        <div class="column is-full ">
          <span class="w-100 bb-primary-2 has-text-primary mb-2">
            Parts
          </span>

          <div class="purchase-order-card-header has-text-weight-semibold my-4">

            <div>
              <div class="pretty p-icon p-curve p-smooth m-0 is-size-6">
                <input type="checkbox"
                  @click="checkAll"
                  :checked="areAllChecked">
                <div class="state">
                  <i class="icon mdi mdi-check" />
                  <label />
                </div>
              </div>
            </div>
            <div class="left">Description</div>
            <div class="flex">Type</div>

            <div style="direction: rtl;">
              Unit Cost
            </div>

            <div class="flex">
              <span>
                Qty
              </span>
            </div>
          </div>

          <label class="purchase-order-card-small mb-1"
            v-for="(part, index) in poItems"
            :class="{'is-selected': part.checked}"
            :key="index + 'part'">

            <div class="is-flex ml-2">
              <div class="pretty p-icon p-curve p-smooth m-0 is-size-6">
                <input type="checkbox"
                  v-model="part.checked">
                <div class="state">
                  <i class="icon mdi mdi-check" />
                  <label />
                </div>
              </div>
            </div>

            <div class="is-flex is-flex-direction-column">
              <span :title="part.itemDesc">{{ part.itemDesc }}</span>
              <span class="is-size-7"
                title="part no">
                {{ part.partNo }}
              </span>
            </div>

            <div class="flex">{{ part.itemType }}</div>

            <div style="direction: rtl;"
              class="ml-2">
              <vue-numeric class="input has-text-right"
                v-model.number="part.buyPrice"
                :min="0"
                :precision="2" />
            </div>

            <!-- Only show quantities for item types that are parts -->
            <div v-if="part.itemType === 'PART'"
              class="flex">
              <div class="item-qty">
                <vue-numeric class="input has-text-right"
                  :min="1"
                  :disabled="false"
                  :precision="0"
                  @input="checkOrderQty(part)"
                  v-model.number="part.poOrderQty" />
              </div>
              <span class="ml-1">/</span>
              <span>{{ part.itemQuantity - part.quantityOrdered + part.quantityReturned }}</span>
            </div>
            <!-- miscs and sublets -->
            <div v-else
              class="flex">
              N/A
            </div>

          </label>

        </div>

        <span v-if="!isAnyChecked"
          class="has-text-danger ml-4">
          Select at least one item to continue
        </span>

      </div>
    </div>

    <!---------------------------------------------- Invoice & Supplier -------------------------------------------->

    <div class="column is-one-third br-gray-2">
      <div class="columns is-multiline mt-3">

        <div class="column p-0 m-0 is-full "
          style="height: 30px;" />

        <div class="column is-full">
          <span class="w-100 bb-primary-2 has-text-primary mb-2">
            Purchase Order Details
          </span>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Purchase Order No
          </span>
          <div class="is-flex mt-2 ml-2">
            <span>PO. {{ purchaseOrder.purchaseOrderNo }}</span>
          </div>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Total Items
          </span>
          <div class="is-flex mt-2 ml-2">
            <span> {{ filteredItems.length }}</span>
          </div>
        </div>

        <div class="column is-full">
          <div class="column is-half m-0 p-0">
            <div class="field">
              <label class="label">Total Amt. (Exc GST)</label>
              <div class="control">
                <span> {{ $filters.formatCurrency(totalCostIncGst, $userInfo.locale ) }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full ">
          <div class="field">
            <label class="label">Vendor</label>
            <div class="field-body">
              <div class="field ">
                <div class="control is-expanded">
                  <multiselect v-if="vendors"
                    v-model="selectedVendor"
                    deselect-label="Can't remove this value"
                    track-by="name"
                    label="name"
                    :show-labels="false"
                    :options="vendors"
                    :options-limit="assetFilter.pageSize"
                    :loading="isVendorLoading"
                    :internal-search="false"
                    :class="{ 'is-danger' : !hasVendorSelected }"
                    :searchable="true"
                    :allow-empty="true"
                    @search-change="getVendorDropdownFilter"
                    placeholder="Select a vendor">
                    <span class="has-text-danger"
                      slot="noResult">Vendor not found.</span>
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full "
          :class="{'disabled': !sendEmail}">
          <span class=" has-text-weight-semibold">
            Email Address
          </span>

          <div class="field">
            <p class="control has-icons-left has-icons-right"
              :class="{ 'is-loading' : isEmailLoading }">
              <input class="input"
                type="email"
                :class="{ 'is-danger' : !isEmailValid && !!sendEmail }"
                v-model="vendorEmail"
                placeholder="Email">
              <span class="icon is-small is-left">
                <i class="mdi mdi-email" />
              </span>
              <span class="icon is-small is-right">
                <i class="mdi"
                  :class="!isEmailValid && !!sendEmail ? 'has-text-danger mdi-close' : 'mdi-check has-text-success'" />
              </span>
            </p>
          </div>
        </div>

        <div class="column is-full mt-0 pt-0">

          <div class="pretty p-icon p-curve p-smooth m-0 is-size-6 mr-2">
            <input type="checkbox"
              v-model="sendEmail">
            <div class="state">
              <i class="icon mdi mdi-check" />
              <label />
            </div>
          </div>

          <span class=" has-text-weight-semibold">
            Email Purchase Order to Supplier
          </span>

        </div>

        <!-- <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Display Prices
          </span>
          <div class="is-flex mt-2 ml-2">
            <span>c43dcece3453e</span>
          </div>
        </div> -->

      </div>
    </div>
    <!---------------------------------------------- Details -------------------------------------------->
    <div class="column is-one-third">
      <div class="columns is-multiline mt-3">

        <div class="column p-0 m-0 is-full "
          style="height: 30px;">
          <div class="is-flex is-justify-content-end">
            <div class="button mr-2 mt-3 is-danger is-outlined"
              @click="$emit('close')">Cancel</div>
            <div @click="createPo()"
              class="button  mr-2 mt-3 is-success is-outlined"
              :class="{'is-loading' : isPoSaving }">
              Create {{ !!sendEmail ? '& Send' : '' }}
            </div>
          </div>
        </div>
        <div class="column is-full ">
          <span class="w-100 bb-primary-2 has-text-primary">
            Details
          </span>
        </div>

        <div class="column is-full "
          :class="{'disabled': !sendEmail}">
          <span class=" has-text-weight-semibold">
            Subject
          </span>
          <div class="is-flex mt-2">
            <div class="field w-100">
              <div class="control is-expanded w-100">
                <input class="input"
                  type="text"
                  v-model="emailSubject"
                  :class="!emailSubject && !!sendEmail ? 'is-danger' : ''"
                  placeholder="Enter Subject">
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full"
          :class="{'disabled' : !sendEmail}">
          <span class=" has-text-weight-semibold">
            Message
          </span>
          <div class="is-flex mt-2 w-100">
            <div class="field mb-0 w-100">
              <div class="control w-100">
                <textarea class="textarea"
                  v-model="emailMessage"
                  rows="6"
                  maxlength="2000"
                  placeholder="Type Message here" />
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold">
            Order By
          </span>
          <div class="is-flex mt-2">
            <div class="field w-100">
              <div class="control is-expanded w-100">
                <bulma-autocomplete placeholder="Orderer"
                  v-model="orderBy"
                  :data="filteredRecieverList"
                  :keep-first="true"
                  :class-prop="!orderBy ? 'is-invalid' : ''"
                  :loading="false"
                  @input="(v) => handleRecieverChange(v)" />
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full ">
          <span class=" has-text-weight-semibold mb-2">
            Order Date
          </span>
          <div class="is-flex mt-2">
            <v-date-picker v-if="!!purchaseOrder"
              v-model="purchaseOrder.purchaseOrderDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              @input="purchaseOrder.purchaseOrderDate = $filters.fromISOWithCurrentTime($event.toISOString())"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      placeholder="Invoice Date"
                      :value="inputValue"
                      v-on="inputEvents"
                      readonly>
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Guid from '../../../../components/Guid.js'
import _cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'
import PartsControlService from '../PartsControlService'
import VendorService from '@/views/vendor/VendorService'
import _debounce from 'lodash.debounce'
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'
import { PurchaseOrderItemModel } from '@/classes/viewmodels'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import VueNumeric from '@/components/VueNumeric'
import { KeyValuePairModel } from '@/classes/viewmodels'
import { Emailer } from '@/classes'
import { PartTypes, RemarkTypes, AssetTypes, EmailAssetTypes, ItemCategoryTypes } from '@/enums'
import { EmailService } from '@/services'

const emailValidator = new RegExp(
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

export default {
  name: 'PartsControlNewManualPurchaseOrder',
  components: {
    Multiselect,
    BulmaAutocomplete,
    VueNumeric
  },
  props: {
    unorderedParts: {
      type: Array,
      default: () => []
    },
    quoteId: null,
    quoteNo: null,
    insurerId: null,
    customerId: null,
    quote: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      creditorInvoiceId: '',
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      innerValue: _cloneDeep(this.purchaseOrder),
      invoiceError: null,
      selectedInvoiceDate: new Date(),
      validateError: false,
      recieverList: [],
      orderBy: null,
      selectedVendor: null,
      isVendorLoading: false,
      assetFilter: {
        id: Guid.empty(),
        name: '',
        pageIndex: 1,
        pageSize: 50
      },
      purchaseOrder: {},
      vendors: [],
      itemToAdd: [],
      isPoSaving: false,
      poItems: [],

      // email settings
      sendEmail: true,
      isEmailLoading: false,
      emailSubject: 'Purchase Order for Quote ' + this.quoteNo.toString(),
      emailMessage: '',
      vendorEmail: '',
      autoSelectedVendorId: null
    }
  },
  computed: {
    filteredRecieverList() {
      return this.recieverList.filter((item) => item.toLowerCase().includes(this.orderBy?.toLowerCase()))
    },
    filteredItems() {
      // Filter out the items that are marked for removal (delete button)
      return this.poItems.filter((item) => item.checked)
    },
    areAllChecked() {
      return this.poItems.every((item) => item.checked)
    },
    hasVendorSelected() {
      return this.selectedVendor && !_isEmpty(this.selectedVendor)
    },
    isEmailValid() {
      return emailValidator.test(this.vendorEmail)
    },
    isAnyChecked() {
      return this.poItems.some((item) => item.checked)
    },
    isPartsEnquiryValid() {
      return (this.isEmailValid || !this.sendEmail) && this.isAnyChecked && this.orderBy && (!this.sendEmail || this.emailSubject) && this.hasVendorSelected
    },
    totalCostIncGst() {
      return this.filteredItems.reduce((acc, item) => acc + item.buyPrice * item.poOrderQty, 0)
    }
  },
  watch: {
    // vendor ID
    selectedVendor: {
      handler: function (val) {
        if (!!val) {
          this.purchaseOrder.vendorId = val.id
          this.getVendorEmail(val)
        }
      },
      deep: true
    },
    orderBy: {
      handler: function (val) {
        if (val) this.purchaseOrder.orderer = val
      }
    }
  },
  created() {
    // new purchase order
    this.createNewPurchaseOrder()
    // list of orderers for auto complete
    this.getRecieverList()
    // list of vendors for auto complete

    let poItems = _cloneDeep(this.unorderedParts)
    poItems.forEach((item) => {
      item.checked = true
      if (item.itemType === ItemCategoryTypes.SUBL) {
        item.poOrderQty = 1
      } else {
        item.poOrderQty = item.itemQuantity - item.quantityOrdered + item.quantityReturned
      }
    })
    this.poItems = poItems

    // auto select vendor
    this.handleVendorSelection()

    this.getVendorsDropdown()
  },
  methods: {
    handleVendorSelection() {
      if (this.poItems.length > 0) {
        // get all the vendor ids
        const vendorIds = [...new Set(this.poItems.map((item) => item.vendorId).filter((id) => !!id))]
        // if there is only one vendor, select it
        if (vendorIds.length === 1) {
          this.autoSelectedVendorId = vendorIds[0]
        }
      }
    },

    // get the vendor email automatically when a vendor is selected
    async getVendorEmail(vendor) {
      this.isEmailLoading = true
      const vendorDetails = await VendorService.getEntity(vendor.id)
      const email = vendorDetails.emails.length > 0 ? vendorDetails.emails.find((e) => e.isDefault) || vendorDetails.emails[0] : null
      this.vendorEmail = email?.address
      this.isEmailLoading = false
    },

    checkAll() {
      let check = this.areAllChecked
      this.poItems.forEach((item) => {
        item.checked = !check
      })
    },
    checkOrderQty(part) {
      if (part.poOrderQty > part.itemQuantity - part.quantityOrdered + part.quantityReturned) {
        this.$toast.open({
          message: 'Purchase order quantity is greater than the quantity of the item',
          type: 'is-warning',
          position: 'is-bottom',
          queue: false
        })
      }
    },

    // create the purchase order
    async createPo() {
      if (!this.isPartsEnquiryValid) {
        this.$toast.open({
          message: 'Please review the form and ensure all fields are filled in correctly.',
          type: 'is-danger',
          position: 'is-bottom',
          queue: false
        })
        return
      }

      this.purchaseOrder.vendorId = this.selectedVendor.id

      this.isPoSaving = true

      // change item type to the correct type (purchase order items)
      const purchaseOrder = this.createItems(this.purchaseOrder)
      let result = await PartsControlService.postEntity(purchaseOrder)

      // Build the email and send it if the user has selected to send the email
      if (this.sendEmail) {
        this.$notification.success('Purchase Order', 'Purchase Order Created Successfully')

        this.$notification.success('Emailing Purchase Order', 'Please wait...')

        const params = {
          PONO: result.data.prefix + result.data.purchaseOrderNo + result.data.suffix,
          COMPANYNAME: encodeURIComponent(this.$userInfo.companyName),
          REPORTTITLE: 'PURCHASE ORDER',
          COMPANYID: this.$userInfo.companyId,
          DISPLAYPART: true, //false
          DISPLAYPRICE: true, //false
          VendorID: result.data.vendorId
        }

        try {
          // Create KeyValuePairs for report
          const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
          const emailer = new Emailer()
          emailer.assetId = this.quote.quoteId
          emailer.assetType = EmailAssetTypes.Quote
          emailer.assetTypes = [AssetTypes.Customer, AssetTypes.Insurer]
          emailer.toEmails = this.vendorEmail ? [this.vendorEmail] : []
          emailer.remark = `${this.quote.prefix}${this.quote.quoteNo}${this.quote.suffix}`
          emailer.remarkTypeId = RemarkTypes.quote
          emailer.subject = this.emailSubject
          emailer.reportName = `PurchaseOrder_${this.quote.prefix}${this.quote.quoteNo}${this.quote.suffix}`
          emailer.message = this.emailMessage + '\n\n' + 'Requested By: ' + this.orderBy

          const formData = new FormData()

          formData.append('emailer', JSON.stringify(emailer))
          const objGenerateReport = {
            ReportName: 'AT_PurchaseOrder',
            Format: 'pdf',
            KeyValuePairs: keyValuePairs
          }
          formData.append('generateReport', JSON.stringify(objGenerateReport))

          const resultEmail = await EmailService.send(formData)
          this.isPoSaving = false

          if (resultEmail.status === 200) {
            this.$emit('close', 'Email Sent', true, result?.data?.purchaseOrderId)
          } else {
            this.$emit('close', 'Error Sending Email', true, result?.data?.purchaseOrderId)
          }
        } catch {}
      } else {
        this.isPoSaving = false
        this.$emit('close', 'Purchase Order Created', true, result?.data?.purchaseOrderId)
      }
    },
    async createNewPurchaseOrder() {
      let purchaseOrder = await PartsControlService.getNewEntity()
      purchaseOrder.quoteId = this.quoteId
      purchaseOrder.quoteNo = this.quoteNo.toString()
      purchaseOrder.insurerId = this.insurerId
      purchaseOrder.customerId = this.customerId
      this.purchaseOrder = purchaseOrder
    },

    // create items for the purchase order, and set the items to the correct type
    createItems(po) {
      let parts = []
      let sublets = []
      let Misc = []
      let items = _cloneDeep(this.filteredItems)
      items.forEach((item) => {
        let newItem = new PurchaseOrderItemModel(
          this.$userInfo.companyId,
          this.purchaseOrder.purchaseOrderId,
          item.quoteItemId,
          item.itemNo,
          item.itemDesc,
          item.partNo,
          item.itemQuantity,
          item.quantityOrdered, ///otherPoOrderedQty,
          item.side,
          false,
          '',
          '',
          '',
          '',
          item.markupValue
        )

        let mark = item.mark // toString
        const index = Object.values(PartTypes).findIndex((item) => item.value === mark)
        newItem.mark = Object.values(PartTypes)[index]?.description

        newItem.quantity = item.poOrderQty || 1

        newItem.unitPrice = item.buyPrice
        // newItem.quantity = quantity // item.itemQuantity - item.otherPoOrderedQty
        newItem.dealerCost = roundAwayFromZero(item.buyPrice * item.poOrderQty, 2)
        // newItem.otherPoOrderedQty = item.otherPoOrderedQty
        newItem.totalInStock = item.quantityInStock || 0

        if (item.itemType === 'PART') {
          parts.push(newItem)
        } else if (item.itemType === 'Sublet') {
          sublets.push(newItem)
        } else {
          Misc.push(newItem)
        }
      })

      po.parts = parts
      po.sublets = sublets
      po.miscs = Misc

      return po
    },
    async getVendorsDropdown() {
      this.isVendorLoading = true
      this.vendors = await VendorService.getVendorDropdownProto2(this.assetFilter)

      if (this.autoSelectedVendorId) {
        this.selectedVendor = this.vendors.find((c) => c.id.toLowerCase() === this.autoSelectedVendorId.toLowerCase())
        this.autoSelectedVendorId = null

        this.$toast.open({
          message: 'Vendor Selected Automatically. Please review the vendor details.',
          type: 'is-success',
          position: 'is-bottom',
          queue: false
        })
      } else if (this.assetFilter.id !== Guid.empty()) {
        this.selectedVendor = this.vendors.find((c) => c.id === this.innerValue.vendorId)
      }
      this.isVendorLoading = false
      return this.vendors
    },
    getVendorDropdownFilter: _debounce(async function (query) {
      this.isVendorLoading = true
      this.assetFilter.name = query
      this.vendors = await VendorService.getVendorDropdownProto2(this.assetFilter)
      this.isVendorLoading = false
    }, 500),
    async getRecieverList() {
      let recList = await PartsControlService.getOrderersDropdown()
      this.recieverList = recList
      // set the reciever as the user thats currently logged in
      const currentReciever = recList.find((i) => i.toLowerCase() === this.$userInfo?.displayName.toLowerCase())
      this.orderBy = currentReciever
      this.purchaseOrder.orderer = currentReciever
    },
    handleRecieverChange(value = null) {
      this.orderBy = value ? value : null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/style/themes/default/_variables.scss';
.bb-primary-2 {
  border-bottom: 2px solid $primary;
}
.br-gray-2 {
  border-right: 1px solid #adb5bd;
}

.w-100 {
  display: flex;
  width: 100%;
}

.purchase-order-card-small {
  border: 1px solid #adb5bd;
  height: 42px !important;
  opacity: 0.8;
  &.is-selected {
    opacity: 1;
  }
  &:hover {
    cursor: pointer;
    border-color: $primary !important;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  }
}

.purchase-order-card-header {
  border: 1px solid transparent;
  padding-left: 5px;
  padding-right: 5px;
}

.purchase-order-card-small,
.purchase-order-card-header {
  display: grid;
  ////  #, checkbox Description, type, side,  Price  Qty
  grid-template-columns: 10% 40% 10% 20% 20%;
  border-radius: 5px;
  align-items: center;

  div {
    &.flex {
      display: flex;
      justify-content: center;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      align-items: center;
    }

    span {
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.date-field {
  border: 1px solid #adb5bd;
  border-radius: 5px;
}

.item-qty {
  min-width: 40px;
  max-width: 40px;
  input {
    width: 100%;
  }
}
</style>
